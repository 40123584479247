@import './fonts/cloud-regular/cloud-regular';
@import './fonts/cloud-bold/cloud-bold';
@import './fonts/cloud-thin/cloud-thin';
@import './fonts/marydale-bold/marydale-bold';

.bold {
  font-family: 'cloud', sans-serif;
  font-weight: bold;
}

.thin {
  font-family: 'cloud', sans-serif;
  font-weight: 100;
}

.script {
  font-family: 'marydale-bold', sans-serif;
  font-style: italic;
}

.vertical-middle {
  vertical-align: middle;
}
