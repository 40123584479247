@font-face {
  font-family: 'marydale-bold';
  src: url('MarydaleBold.eot');
  src: url('MarydaleBold.eot?#iefix') format('embedded-opentype'),
  url('MarydaleBold.woff2') format('woff2'),
  url('MarydaleBold.woff') format('woff'),
  url('MarydaleBold.ttf') format('truetype'),
  url('MarydaleBold.svg#MarydaleBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

