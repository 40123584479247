@font-face {
  font-family: 'cloud';
  src: url('Cloud-Thin.eot');
  src: url('Cloud-Thin.eot?#iefix') format('embedded-opentype'),
  url('Cloud-Thin.woff2') format('woff2'),
  url('Cloud-Thin.woff') format('woff'),
  url('Cloud-Thin.ttf') format('truetype'),
  url('Cloud-Thin.svg#Cloud-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
