@use "sass:map";

$colors-values: (
  'coral': ('red': 255, 'green': 81, 'blue': 95),
  'aqua': ('red': 158, 'green': 198, 'blue': 208),
  'aqua-dark': ('red': 98, 'green': 160, 'blue': 184),
  'indigo': ('red': 42, 'green': 40, 'blue': 86),
  'indigo-light': ('red': 56, 'green': 56, 'blue': 112),
  'orange': ('red': 255, 'green': 116, 'blue': 77),
);

$colors-rgb: (
  'coral': '#{map.get(map.get($colors-values, 'coral'), 'red')},#{map.get(map.get($colors-values, 'coral'), 'green')},#{map.get(map.get($colors-values, 'coral'), 'blue')}',
  'aqua': '#{map.get(map.get($colors-values, 'aqua'), 'red')},#{map.get(map.get($colors-values, 'aqua'), 'green')},#{map.get(map.get($colors-values, 'aqua'), 'blue')}',
  'aqua-dark': '#{map.get(map.get($colors-values, 'aqua-dark'), 'red')},#{map.get(map.get($colors-values, 'aqua-dark'), 'green')},#{map.get(map.get($colors-values, 'aqua-dark'), 'blue')}',
  'indigo': '#{map.get(map.get($colors-values, 'indigo'), 'red')},#{map.get(map.get($colors-values, 'indigo'), 'green')},#{map.get(map.get($colors-values, 'indigo'), 'blue')}',
  'indigo-light': '#{map.get(map.get($colors-values, 'indigo-light'), 'red')},#{map.get(map.get($colors-values, 'indigo-light'), 'green')},#{map.get(map.get($colors-values, 'indigo-light'), 'blue')}',
  'orange': '#{map.get(map.get($colors-values, 'orange'), 'red')},#{map.get(map.get($colors-values, 'orange'), 'green')},#{map.get(map.get($colors-values, 'orange'), 'blue')}',
);

$colors: (
  'coral': rgb(map.get(map.get($colors-values, 'coral'), 'red'), map.get(map.get($colors-values, 'coral'), 'green'), map.get(map.get($colors-values, 'coral'), 'blue')),
  'coral-alpha': rgb(map.get(map.get($colors-values, 'coral'), 'red'), map.get(map.get($colors-values, 'coral'), 'green'), map.get(map.get($colors-values, 'coral'), 'blue'), 0.35),
  'aqua': rgb(map.get(map.get($colors-values, 'aqua'), 'red'), map.get(map.get($colors-values, 'aqua'), 'green'), map.get(map.get($colors-values, 'aqua'), 'blue')),
  'aqua-dark': rgb(map.get(map.get($colors-values, 'aqua-dark'), 'red'), map.get(map.get($colors-values, 'aqua-dark'), 'green'), map.get(map.get($colors-values, 'aqua-dark'), 'blue')),
  'indigo': rgb(map.get(map.get($colors-values, 'indigo'), 'red'), map.get(map.get($colors-values, 'indigo'), 'green'), map.get(map.get($colors-values, 'indigo'), 'blue')),
  'indigo-light': rgb(map.get(map.get($colors-values, 'indigo-light'), 'red'), map.get(map.get($colors-values, 'indigo-light'), 'green'), map.get(map.get($colors-values, 'indigo-light'), 'blue')),
  'orange': rgb(map.get(map.get($colors-values, 'orange'), 'red'), map.get(map.get($colors-values, 'orange'), 'green'), map.get(map.get($colors-values, 'orange'), 'blue')),
  'orange-alpha': rgb(map.get(map.get($colors-values, 'orange'), 'red'), map.get(map.get($colors-values, 'orange'), 'green'), map.get(map.get($colors-values, 'orange'), 'blue'), 0.35),
);

$coral: rgb(255, 81, 95);
$aqua: rgb(158, 198, 208);
$aqua-dark: rgb(98, 160, 184);
$indigo: rgb(42, 40, 86);
$indigo-light: rgb(56, 56, 112);
$orange: rgb(255, 116, 77);

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value;
  }

  .bg-#{$color} {
    background-color: $value;
  }
}

