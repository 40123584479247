@use '@angular/material' as mat;
@use 'sass:map';

/* Branding */
@import 'theme/branding';
@import 'theme/ionic-customization';
@import 'node_modules/quill/dist/quill.core.css';
@import 'node_modules/quill/dist/quill.snow.css';


$fontConfig: (
  display-4: mat.m2-define-typography-level(112px, 112px, 300, 'cloud', -0.0134em),
  display-3: mat.m2-define-typography-level(56px, 56px, 400, 'cloud', -0.0089em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, 'cloud', 0.0000em),
  display-1: mat.m2-define-typography-level(34px, 40px, 400, 'cloud', 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 400, 'cloud', 0.0000em),
  title: mat.m2-define-typography-level(20px, 32px, 500, 'cloud', 0.0075em),
  subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'cloud', 0.0094em),
  subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'cloud', 0.0067em),
  body-2: mat.m2-define-typography-level(14px, 24px, 500, 'cloud', 0.0179em),
  body-1: mat.m2-define-typography-level(14px, 20px, 400, 'cloud', 0.0179em),
  button: mat.m2-define-typography-level(14px, 14px, 500, 'cloud', 0.0893em),
  caption: mat.m2-define-typography-level(12px, 20px, 400, 'cloud', 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'cloud', 1.5px)
);


// Light Theme Text
$dark-text: #2a2856;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2a2856, 20%);
$dark-bg-alpha-4: rgba(#2a2856, 0.04);
$dark-bg-alpha-12: rgba(#2a2856, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2a2856;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);


@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.m2-define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $fontConfig,
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  font-family: 'cloud', sans-serif;
  padding: 0;
  margin: 0;
  color: map.get($colors, 'indigo');
}

.mdc-snackbar {
  &.error {
    --mdc-snackbar-supporting-text-color: var(--mat-form-field-error-text-color);
  }
}

mat-chip {
  vertical-align: middle;
  margin-left: 4px;
}

.mat-mdc-chip {
  --mdc-chip-with-avatar-avatar-shape-radius: 0;
}

button {
  mat-label {
    vertical-align: sub;
  }

  & ~ button {
    margin-left: 8px;
  }
}

.ql-editor {
  font-family: 'cloud', sans-serif;
  color: map.get($colors, 'indigo');
}
