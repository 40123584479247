ion-accordion-group {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-inline-start: -32px;
  margin-inline-end: 0;
}

ion-accordion {
  border-radius: 0 8px 8px 0;

  ion-item {
    --padding-start: 32px;

    ion-label {
      margin-bottom: 7px;
    }
  }
}
