@font-face {
  font-family: 'cloud';
  src: url('Cloud.eot');
  src: url('Cloud.eot?#iefix') format('embedded-opentype'),
  url('Cloud.woff2') format('woff2'),
  url('Cloud.woff') format('woff'),
  url('Cloud.ttf') format('truetype'),
  url('Cloud.svg#Cloud') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
